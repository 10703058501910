import { HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "./services/api.service";

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

  constructor(private apiService: ApiService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = this.apiService.getToken();
    if (token) {
      const cloned = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
      return next.handle(cloned);
    } else {
      console.log('handle request');
      return next.handle(request);
    }
  }

}
