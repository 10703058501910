import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = 'https://sunapp-backend.elan.digital/api';
  private token: string;

  constructor(private http: HttpClient, private storage: Storage) {

  }

  async initialize() {
    await this.storage.create();
    this.token = await this.storage.get('token');
    console.log(this.token);
  }

  login(formData) {
    return this.http.post(this.baseUrl + '/login', formData).toPromise();
  }

  predict(lat, lng, timezone, day) {
    return this.http.get(this.baseUrl + '/predict?lat='+lat+'&lng='+lng+'&timezone='+timezone+'&day='+day).toPromise();
  }

  getToken() {
    return this.token;
  }

  async setToken(token) {
    await this.storage.set('token', token);
    this.token = token;
  }

}
